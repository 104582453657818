import { ACCENT } from "constants/colors";
import Col from "components/atoms/col";
import Space from "components/atoms/space";
import { useMediaQuery } from 'react-responsive'
import React from "react";
import { HSPACE, SPACE_6X } from "constants/dimen";
import { faMobile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SOCIAL_MENU } from 'data/socialMenu';
import { MenuItem } from "components/molecules/menuItem";
import Row from "components/atoms/row";

export default function Intro() {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const imageSize = 250
    const mobileIconSize = 50

    const styles = {
        circle: {
            borderRadius: '5%',
            background: 'grey'
        },
        primaryTitle: {
            fontSize: '2.5em',
            color: ACCENT,
            display: 'flex',
        },
        secondaryTitle: {
            fontSize: '2em',
            color: 'white'
        },
        revertibleRow: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: isTabletOrMobile ? 'column-reverse' : 'row'
        }
    }

    const onSocialMenuClick = (index) => {
        const link = SOCIAL_MENU[index].link;

        if (link) {
            window.open(link);
        }

    }

    return (
        <>
            <Space height={SPACE_6X} />
            {
                <div style={styles.revertibleRow}>
                    <Col full alignItems={isTabletOrMobile ? 'center' : 'start'} padding={0}>
                        <div style={styles.primaryTitle}>
                            <FontAwesomeIcon
                                fontSize={mobileIconSize}
                                width={mobileIconSize}
                                height={mobileIconSize}
                                color={ACCENT}
                                icon={faMobile}
                            />
                            Lead Engineer.
                        </div>
                        <Space height={HSPACE} />
                        <div style={styles.secondaryTitle}>Algorithms Geek.</div>
                        <div style={styles.secondaryTitle}>Android Expert.</div>
                    </Col>

                    {isTabletOrMobile && <Space height={40} />}

                    <Col>
                        <img
                            src="/gio.jpg"
                            alt="Giorgio Baldassarre"
                            style={styles.circle}
                            width={imageSize}
                            height={imageSize}
                        />

                        <Row>
                            {
                                SOCIAL_MENU.map
                                    (
                                        (el, index) =>
                                            <MenuItem
                                                iconSize={40}
                                                key={`menu: ${el.name}`}
                                                onClick={() => onSocialMenuClick(index)}
                                                icon={el.icon}
                                            />
                                    )
                            }
                        </Row>

                    </Col>
                </div>
            }
        </>
    )
}